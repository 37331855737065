import { TextField } from '@mui/material';
import { styled } from '@mui/system';

const BaseInput = styled(TextField)(({ theme, ...props }) => ({
  '& input': {
    textAlign: props.textAlign ?? 'left',
  },
  '& p.MuiFormHelperText-root': {
    textAlign: props.helperTextAlign ?? 'center',
    color: theme.palette.grey[800],
  },
  '& textarea': {
    paddingTop: '5px',
  },
}));

export default BaseInput;
