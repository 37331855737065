import BaseInput from '../default/base-input';
import React from 'react';
import { useController } from 'react-hook-form';

const pattern = {
  // number , space , + sign, backspace
  phoneNumberInput: /^[0-9\b+ ]+$/,
};

// validation for input (must match pattern and min:9 max:12)
const validate = (value) => {
  const valueLength = value.replaceAll(' ', '').length; // remove space
  if (valueLength === 0) return true;
  const matchPattern = pattern.phoneNumberInput.test(valueLength);
  return matchPattern && valueLength >= 9 && valueLength <= 12;
};

const RHFPhoneInput = (props) => {
  const {
    name,
    control,
    required,
    variant = 'outlined',
    size = 'small',
    defaultValue = '',
    ...textFieldProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    control, //optional when use inside formContext
    rules: { required, validate },
  });

  // only allow value that match pattern
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value === '' || pattern.phoneNumberInput.test(value)) {
      onChange(value);
    }
  };

  return (
    <BaseInput
      variant={variant}
      required={required}
      size={size}
      value={value}
      onChange={handleChange}
      inputRef={ref}
      error={!!error}
      {...textFieldProps}
      {...inputProps}
    />
  );
};

export default RHFPhoneInput;
