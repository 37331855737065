import LOGIN from './login.json';
import COMMON from './common.json';
import POST from './post.json';
import FRAME from './frame.json';
import ACCOUNT_SETTING from './account-setting.json';

export const TRANSLATIONS_EN = {
  ...LOGIN,
  ...COMMON,
  ...POST,
  ...FRAME,
  ...ACCOUNT_SETTING,
};
