import { Paper } from '@mui/material';
import React from 'react';

// Page Wrapper to make all page consistance with same padding
const PageWrapper = ({ children }) => {
  return (
    <Paper
      sx={{
        px: { xs: 3, md: 6 },
        py: { xs: 3, md: 5 },
        borderRadius: '16px',
        overflow: 'auto',
        position: 'relative',
      }}
      elevation={2}
    >
      {children}
    </Paper>
  );
};

export default PageWrapper;
