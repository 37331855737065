import { useController } from 'react-hook-form';
import BaseInput from '../default/base-input';

const RHFTextInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = '',
    rules,
    onChange,
    ...textFieldProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: ucOnChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, ...rules },
    defaultValue,
  });

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    ucOnChange(value);
  };

  return (
    <BaseInput
      required={required}
      value={value}
      onChange={onChange ? (data) => onChange(data, ucOnChange) : handleChange}
      inputRef={ref}
      error={!!error}
      {...textFieldProps}
      {...inputProps}
    />
  );
};

export default RHFTextInput;
