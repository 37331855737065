import compressImage from './compress-image';
import generateRandomKey from './generate-random-key';

const prepareImageUpload = async ({
  file,
  baseKey,
  compressMode = 'image',
}) => {
  const fileType = file.type;
  const fileExtension = file.type.split('/')[1];

  const key = `${baseKey}/${generateRandomKey()}`;
  const imageKey = `${key}.${fileExtension}`;

  // compress image
  const compressedImage = await compressImage(file, compressMode);

  return {
    key: imageKey,
    type: fileType,
    image: compressedImage,
  };
};

export default prepareImageUpload;
