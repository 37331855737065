import axios from './utils/custom-axios';

const Auth = {
  login: (data) => {
    return axios.post('auth/login', data);
  },
  getMe: () => {
    return axios.get('auth/users/me');
  },
  updateMe: (data) => {
    return axios.put('auth/users/me', data);
  },
  logout: () => {
    return axios.get('auth/logout');
  },
  facebook: {
    login: `${axios.defaults.baseURL}/auth/facebook`,
  },
};

export default Auth;
