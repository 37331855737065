import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Avatar, ListItemIcon, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ExpandMore } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from 'src/context/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PersonIcon from '@mui/icons-material/Person';

const Profile = () => {
  const { user, loading, logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
    } catch (err) {
      console.log(err?.response?.data?.message ?? err.message);
    } finally {
      navigate('/login');
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToAccountSetting = () => {
    navigate('/dashboard/settings/account-setting');
    handleClose();
  };

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Stack>
        <Typography variant="caption">Greeting!</Typography>
        <Typography align="right">{user?.user.fullname}</Typography>
      </Stack>
      <Button variant="text" disableRipple onClick={handleClick}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar src={user?.user?.id?.profile?.src} />
          <ExpandMore sx={{ color: 'white' }} />
        </Stack>
      </Button>
      <Menu
        sx={{ marginTop: 1 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {!user.isExpired && (
          <MenuItem onClick={navigateToAccountSetting}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            {t('account')}
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('logout-btn')}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Profile;
