import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './sample-redux/counterSlice';
import publishReducer from './publish-redux/publishSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    publish: publishReducer,
  },
});
