import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthProvider';

//  auth = true : if want navigate when user is login
//  auth = false : if want navigate when use is not login

const PrivateRoute = ({ children, auth = false, navigate, condition }) => {
  const { loading, user } = useAuth();
  const location = useLocation();

  if (loading) return <LinearProgress />;

  let isAuth = false;
  if (user) {
    isAuth = true;
  }

  if (condition) {
    const result = condition({
      user,
      isAuth,
      location,
      children,
    });

    return result ?? null;
  }

  return isAuth !== auth ? children : <Navigate to={navigate} />;
};

export default PrivateRoute;
