import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/components/page-wrapper';
import TabPanel from 'src/components/tabs/tab-panel';
import Profile from './profile';

const AccountSettingPage = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => setValue(newValue);

  return (
    <PageWrapper>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab value={value} label={t('profile')} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{ pt: 3 }}>
        <Profile />
      </TabPanel>
    </PageWrapper>
  );
};

export default AccountSettingPage;
