import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useRoutes } from 'react-router-dom';
import ROUTES_PATH from 'src/routes';
import theme from 'src/themes';

function App() {
  const ROUTES = useRoutes(ROUTES_PATH);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {ROUTES}
    </ThemeProvider>
  );
}

export default App;
