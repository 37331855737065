import { Badge } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CameraIconButton from './CameraIconButton';
import AvatarButton from './AvatarButton';
import ProfileEditor from './ProfileEditor';
import { useController } from 'react-hook-form';

const ProfilePictureEditor = ({
  name,
  control,
  required,
  defaultValue = null,
}) => {
  const [editor, setEditor] = useState({ state: false });
  const [image, setImage] = useState({ file: null, src: defaultValue });

  // using react hook form useController as value state
  const {
    field: { value, onChange },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required },
    defaultValue: image,
  });

  const fileRef = useRef();

  const openEditor = () => {
    setEditor((prev) => ({ ...prev, state: true }));
  };

  const closeEditor = () => {
    setEditor((prev) => ({ ...prev, state: false }));
  };

  const handleCloseEditor = () => {
    closeEditor();
    fileRef.current.value = '';
  };

  const handleBrowseImage = () => {
    fileRef.current.click();
  };

  const handleImageChange = async (e) => {
    try {
      const files = e.target.files;
      if (files.length > 0) {
        setImage((prev) => ({ ...prev, file: files[0] }));
        openEditor();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleImageSave = (image) => {
    onChange(image);
  };

  useEffect(() => {
    if (!value.file && value.src) {
      setImage((prev) => ({ ...prev, ...value }));
    }
  }, [value]);

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={<CameraIconButton onClick={handleBrowseImage} />}
    >
      <ProfileEditor
        image={image}
        setImage={setImage}
        open={editor.state}
        onBrowse={handleBrowseImage}
        onClose={handleCloseEditor}
        onImageSave={handleImageSave}
      />
      <AvatarButton image={image} onClick={handleBrowseImage} />
      <input
        onChange={handleImageChange}
        ref={fileRef}
        type="file"
        hidden
        accept="image/*"
      ></input>
    </Badge>
  );
};

export default ProfilePictureEditor;
