import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

export const publishSlice = createSlice({
  name: 'publish',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { updateLoading } = publishSlice.actions;

export const selectLoading = (state) => state.publish.loading;

export default publishSlice.reducer;
