import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PasswordInput from 'src/components/password-input';
import {
  RHFPhoneInput,
  RHFTextInput,
  RHFProfilePictureEditor,
} from 'src/components/rhf-controlled-input';
import { useAuth } from 'src/context/AuthProvider';
import AUTH_API from 'src/api/auth';
import { useSnackbar } from 'notistack';
import prepareImageUpload from 'src/utils/prepare-image-upload';
import UPLOAD_API from 'src/api/upload';
import imageCompression from 'browser-image-compression';
import Spinner, { useSpinner } from 'src/components/spinner';
import compressImage from 'src/utils/compress-image';

const Profile = () => {
  const methods = useForm();
  const { t } = useTranslation();
  const { getMe, user } = useAuth();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  const { enqueueSnackbar } = useSnackbar();

  const resetForm = (me) => {
    const {
      user: {
        id: { firstname, lastname, profile },
        email,
        phone,
      },
    } = me;

    methods.reset({
      firstname,
      lastname,
      email,
      phone: phone ?? '',
      profile: { src: profile?.src ?? null },
      password: '',
      confirmPassword: '',
    });
  };

  const onSubmit = async (data) => {
    try {
      openSpinner({ title: t('saving') });
      const { profile, password, confirmPassword, ...updateUserInfo } = data;

      if (profile.file) {
        // remove old profile
        if(user?.user?.id?.profile?.key){
          await UPLOAD_API.deleteImages([{ Key: user?.user?.id?.profile?.key }]);
        }

        const imageFile = await imageCompression.getFilefromDataUrl(
          profile.file
        );

        // upload new profile
        const { key, type, image } = await prepareImageUpload({
          file: imageFile,
          baseKey: `users/${user.user.id._id}/upload/profile`,
          compressMode: 'profile',
        });

        const uploadResponse = await UPLOAD_API.getUploadURL([
          { Key: key, ContentType: type },
        ]);

        const uploadURL = uploadResponse.data.uploadURLArray?.[0]?.uploadURL;

        await UPLOAD_API.uploadImage({ url: uploadURL, file: image, type });

        updateUserInfo.profile = { key, type };
      }

      if (password) {
        updateUserInfo.password = password;
      }

      const response = await AUTH_API.updateMe(updateUserInfo);
      const me = await getMe();
      resetForm(me);
      enqueueSnackbar(response.data.message, { variant: 'success' });
      closeSpinner();
    } catch (err) {
      enqueueSnackbar(err.response.data.message, { variant: 'error' });
      console.error(err);
    } finally {
      closeSpinner();
    }
  };

  useEffect(() => {
    const getMyUser = async () => {
      openSpinner({ title: t('loading') });
      try {
        const me = await getMe();
        resetForm(me);
      } catch (err) {
        console.error(err);
      } finally {
        closeSpinner();
      }
    };
    getMyUser();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="center">
            <RHFProfilePictureEditor name="profile" />
          </Grid>
          <Grid item>
            <Typography gutterBottom>{t('user-info')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <RHFTextInput
                  label={t('first-name')}
                  variant="outlined"
                  name="firstname"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextInput
                  label={t('last-name')}
                  variant="outlined"
                  name="lastname"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextInput
                  label={t('email')}
                  variant="outlined"
                  type="email"
                  name="email"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFPhoneInput
                  label={t('phone')}
                  variant="outlined"
                  name="phone"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography gutterBottom>{t('password')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <PasswordInput
                  label={t('new-password')}
                  variant="outlined"
                  name="password"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <PasswordInput
                  label={t('confirm-new-password')}
                  variant="outlined"
                  name="confirmPassword"
                  rules={{
                    validate: (value) => {
                      return value === methods.getValues('password');
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              disabled={!methods.formState.isDirty}
              variant="contained"
              type="submit"
              disableElevation
            >
              {t('save-changes')}
            </Button>
          </Grid>
        </Grid>
        <Spinner state={spinnerState} />
      </form>
    </FormProvider>
  );
};

export default Profile;
