import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { RHFTextInput } from 'src/components/rhf-controlled-input';

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <RHFTextInput
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton
            edge="end"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordInput;
