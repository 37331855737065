const globalStyle = (theme) => ({
  /* change style scroll bar - Dont work on firefox */
  /* width */
  '*::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '*': {
    scrollbarWidth: 'thin',
  },
  /* Track */
  '* ::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  /* Handle */
  '* ::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '10px',
  },

  // global classname
  '& .ellipsis': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default globalStyle;
