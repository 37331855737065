import { Avatar, ButtonBase } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const style = {
  buttonBase: {
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    transition: '0.1s linear',
    ':hover': {
      backgroundColor: 'action.hover',
    },
  },
  avatar: {
    width: '120px',
    height: '120px',
    padding: 0,
  },
};

const ClickableAvatar = ({ image, onClick }) => {
  return (
    <ButtonBase onClick={onClick} sx={style.buttonBase}>
      <Box aria sx={style.overlay} />
      <Avatar src={image.src} sx={style.avatar} />
    </ButtonBase>
  );
};

export default ClickableAvatar;
