import { ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useAccessControl } from 'src/components/access-control';
import { useAuth } from 'src/context/AuthProvider';
import menuConfig from './menu-config';
import buildPath from './utils/build-path';

const CustomNavLink = ({ show = true, ...props }) => {
  return show ? <ListItemButton component={NavLink} {...props} /> : null;
};

const CustomNavLinkStyle = styled(CustomNavLink)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    paddingLeft: '32px',
    '& svg': {
      color: 'white',
    },
    color: 'white',
    '&.active': {
      backgroundColor: theme.palette.drawer.selected,
    },
    '&:hover:not(.active)': {
      backgroundColor: theme.palette.drawer.hover,
    },
  },
}));

const CustomSubNavLinkStyle = styled(CustomNavLinkStyle)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    '&.active': {
      backgroundColor: theme.palette.drawer.subSelected,
    },
  },
}));

const basePath = menuConfig.basePath;
const menu = menuConfig.menu;

const Menu = ({ onClose }) => {
  const checkAccessControl = useAccessControl();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('menu'); //using namespace
  const { user } = useAuth();

  return (
    <List>
      {menu.map((item, index) => {
        const Icon = item.icon;
        const path = buildPath(basePath, item);
        const isMatch = location.pathname.includes(buildPath(basePath, item));

        // permission check
        let show = true;

        if (item.hidden) {
          show = !item.hidden(user);
        } else if (item.access) {
          show = checkAccessControl(...item.access);
        }

        return (
          <React.Fragment key={index}>
            <CustomNavLinkStyle
              show={show}
              component={NavLink}
              end={item.exact}
              to={path}
              onClick={(e) => {
                e.preventDefault(); //to keep using parent active functionality
                navigate(
                  item.children
                    ? buildPath(basePath, item, item.children?.[0])
                    : path
                );
                onClose();
              }}
            >
              <ListItemIcon>{item.icon && <Icon />}</ListItemIcon>
              <ListItemText primary={t(item.label)} />
              {show && item.children && (
                <ListItemIcon>
                  <ExpandMore />
                </ListItemIcon>
              )}
            </CustomNavLinkStyle>
            {show && item.children && (
              <Collapse in={isMatch}>
                {item.children.map((subItem, index) => {
                  // permission check
                  let subShow = true;
                  if (subItem.access) {
                    subShow = checkAccessControl(...subItem.access);
                  }
                  return (
                    <CustomSubNavLinkStyle
                      show={subShow}
                      key={index}
                      end={subItem?.exact}
                      to={buildPath(basePath, item, subItem)}
                      onClick={(e) => {
                        e.preventDefault(); //to keep using active functionality
                        navigate(buildPath(basePath, item, subItem));
                        onClose();
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={t(subItem?.label)} />
                    </CustomSubNavLinkStyle>
                  );
                })}
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default Menu;
