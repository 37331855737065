import axios from './utils/custom-axios';

const Upload = {
  getUploadURL: (data) => {
    return axios.post('s3/upload', data);
  },
  uploadImage: ({ url, file, type }) => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': type,
      },
      withCredentials: false,
    });
  },
  deleteImages: (KeyList) => {
    return axios.post('s3/delete', KeyList);
  },
};

export default Upload;
