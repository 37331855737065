import { Navigate, Outlet } from 'react-router';
import DashboardLayout from 'src/layouts/dashboard';
import PrivateRoute from 'src/components/private-route';
import React, { lazy, Suspense } from 'react';
import { LinearProgress } from '@mui/material';
import SuccessPage from 'src/pages/Login/success';
import FailPage from 'src/pages/Login/fail';
import AccountSettingPage from 'src/pages/Account-Setting';

// lazy loading
const Login = lazy(() => import('src/pages/Login'));
const Dashboard = lazy(() => import('src/pages/Dashboard'));
const ExpiredPage = lazy(() => import('src/pages/Expired'));
const ListPostsPage = lazy(() => import('src/pages/Posts/List'));
const PostFormPage = lazy(() => import('src/pages/Posts/Form'));
const ListFramesPage = lazy(() => import('src/pages/Frames/List'));
const CreateFramePage = lazy(() => import('src/pages/Frames/Create'));
const RolePermissionSetting = lazy(() =>
  import('src/pages/Role-Permission/Setting')
);

const privateRouteCondition = ({ user, isAuth, location }) => {
  // if user not authenticate go to login page
  if (!isAuth) return <Navigate to="/login" />;
  const result = () => {
    // if user expired
    if (user.isExpired) {
      switch (location.pathname) {
        // change dashboard page to expired
        case '/dashboard': {
          return <ExpiredPage />;
        }
        default: {
          return <Navigate to="/dashboard" />;
        }
      }
    }
    return <Outlet />;
  };

  return (
    <DashboardLayout>
      <Suspense fallback={<LinearProgress />}>{result()}</Suspense>
    </DashboardLayout>
  );
};

const ROUTES_PATH = [
  {
    path: 'login',
    children: [
      {
        index: true,
        element: (
          <PrivateRoute
            condition={({ isAuth }) => {
              // if authenticate navigate to '/dashboard'
              if (isAuth) return <Navigate to="/dashboard" />;
              return (
                <Suspense fallback={<LinearProgress />}>
                  <Login />
                </Suspense>
              );
            }}
          />
        ),
      },
      {
        path: 'success',
        element: <SuccessPage />,
      },
      {
        path: 'failed',
        element: <FailPage />,
      },
    ],
  },
  //PRIVATE ROUTE
  {
    path: 'dashboard',
    element: <PrivateRoute condition={privateRouteCondition} />,
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: 'posts',
        children: [
          {
            index: true,
            element: <ListPostsPage />,
          },
          {
            path: 'create',
            element: <PostFormPage />,
          },
          {
            path: 'edit',
            children: [
              {
                path: ':id',
                element: <PostFormPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'frames',
        children: [
          {
            index: true,
            element: <ListFramesPage />,
          },
          {
            path: 'create',
            element: <CreateFramePage />,
          },
        ],
      },
      {
        path: 'settings',
        children: [
          // {
          //   path: 'roles-permissions',
          //   element: <RolePermissionSetting />,
          // },
          {
            path: 'account-setting',
            element: <AccountSettingPage />,
          },
        ],
      },
      { path: '*', element: <Navigate to="/dashboard" /> },
    ],
  },
  { path: '*', element: <Navigate to="/login" /> },
];

export default ROUTES_PATH;
