import MenuIcon from '@mui/icons-material/Menu';
import Profile from './profile';
import Logo from '../logo';
const {
  AppBar,
  Toolbar,
  IconButton,
  // Button
} = require('@mui/material');
const { Box } = require('@mui/system');

const Navbar = ({
  drawerWidth,
  matchBreakpoint,
  handleOpen,
  // toggle
}) => {
  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          padding: {
            xs: 0,
          },
        }}
      >
        {matchBreakpoint ? (
          <IconButton color="inherit" onClick={handleOpen}>
            <MenuIcon />
          </IconButton>
        ) : (
          <>
            <Logo drawerWidth={drawerWidth} />
            {/* <Button
              sx={{ color: 'white' }}
              // onClick={() => {
              //   toggle();
              // }}
            >
              Collapse
            </Button> */}
          </>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Profile />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
