import imageCompression from 'browser-image-compression';

const options = {
  image: { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true },
  profile: { maxSizeMB: 0.2, maxWidthOrHeight: 500, useWebWorker: true },
};

const compressImage = async (file, option = 'image') => {
  try {
    const compressedImage = await imageCompression(
      file,
      options?.[option] ?? options?.['image']
    );
    return compressedImage;
  } catch (error) {
    console.log(error);
  }
};

export default compressImage;
