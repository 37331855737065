import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const CameraIconButton = ({ onClick }) => (
  <Box
    sx={{
      backgroundColor: 'grey.200',
      border: '3px solid white',
      borderRadius: '50%',
    }}
  >
    <IconButton onClick={onClick} sx={{ border: '1px solid grey.300' }}>
      <CameraAltIcon sx={{ color: 'black' }} />
    </IconButton>
  </Box>
);

export default CameraIconButton;
