import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import globalStyle from './global';

export const primary = {
  main: '#0B4668',
  light: '#006D8A',
  lighter: '#009698',
  hightlight: '#006d8a2b',
};

let theme = createTheme({
  typography: {
    h1: {
      fontSize: '35px',
    },
  },
  palette: {
    white: '#FFF',
    primary,
    secondary: {
      main: '#CFDFFF',
    },
    drawer: {
      background: primary.main,
      hover: '#ffffff0f',
      selected: primary.light,
      subSelected: primary.lighter,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
        elevation2: {
          boxShadow:
            '1px 1px 18px 1px rgb(0 0 0 / 10%), -1px 0px 4px 0px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          minWidth: '100px',
        },
      },
    },
  },
});

//overwrite globalStyle
theme.components.MuiCssBaseline.styleOverrides = globalStyle(theme);

theme = responsiveFontSizes(theme);

export default theme;
