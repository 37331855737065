import React, { useState } from 'react';
import { Container, useMediaQuery } from '@mui/material';
import { Box, styled } from '@mui/system';
import Navbar from './navbar';
import Drawer from './drawer';
import { useTheme } from '@emotion/react';

const RootContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const drawer = {
  fullWidth: '280px',
  minWidth: '90px',
};

const DashboardLayout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [
    drawerWidth,
    //  setDrawerWidth
  ] = useState(drawer.fullWidth);
  const theme = useTheme();
  const matchBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggle = () => {
    // setDrawerWidth((prev) =>
    //   prev === drawer.fullWidth ? drawer.minWidth : drawer.fullWidth
    // );
  };

  return (
    <RootContainer>
      <Navbar
        matchBreakpoint={matchBreakpoint}
        handleOpen={handleOpen}
        drawerWidth={drawerWidth}
        toggle={toggle}
      />
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Drawer
          matchBreakpoint={matchBreakpoint}
          state={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          drawerWidth={drawerWidth}
        />
        <Box
          sx={{ overflow: 'overlay', flexGrow: 1, paddingTop: '24px', pb: 3 }}
        >
          <Container maxWidth="xl">{children}</Container>
        </Box>
      </Box>
    </RootContainer>
  );
};

export default DashboardLayout;
