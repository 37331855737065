import { Drawer } from '@mui/material';
import { Box, styled } from '@mui/system';
import React from 'react';
import Logo from '../logo';
import Menu from './menu';

const DrawerStyle = styled(Drawer)(({ theme, ...props }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: props.width,
    position: 'relative',
    backgroundColor: theme.palette.drawer.background,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const DrawerComponent = ({
  drawerWidth,
  matchBreakpoint,
  state,
  handleClose,
}) => {
  return (
    <DrawerStyle
      onClose={handleClose}
      variant={!matchBreakpoint ? 'permanent' : 'temporary'}
      width={drawerWidth}
      open={state}
    >
      {matchBreakpoint && (
        <Box sx={{ my: 1 }}>
          <Logo />
        </Box>
      )}
      <Menu onClose={handleClose} />
    </DrawerStyle>
  );
};

export default DrawerComponent;
